<template>
    <v-container>
       <v-data-table
      :headers="headers"
      :items="settings"
      sort-by="name"
      class="elevation-1"
      >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>App Settings</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-spacer></v-spacer>
        
        </v-toolbar>
      </template>

      
       <template v-slot:[`item.name`]="{ item }">
  <span style="text-transform:capitalize">{{underScoreFormatter(item.name)}}</span>  
  </template>

      <template v-slot:no-data> No Data </template>

       <template v-slot:item.actions="{ item }">
    
      <v-icon
        small
        @click="openEditDialog(item)"
        class="mr-2"
      >
        mdi-pencil-box
      </v-icon>
        
     
    </template>
    </v-data-table>

    <v-dialog v-model="editDialog" max-width="600">
      <v-toolbar color="primary" dark style="text-transform:capitalize"
        >{{underScoreFormatter(currentSettings.name)}}

        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="white"
        ></v-progress-linear>
      </v-toolbar>
      <v-card class="pa-5" :disabled="loading">
        <v-text-field type="number" label="Value" v-model="currentSettings.value"></v-text-field>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="editDialog = false">
            Cancel
          </v-btn>

          <v-btn class="primary" text @click="editSetting"> Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </v-container>
</template>


<script>
export default {
    data(){
        return{
             editDialog: false,
             settingName: '',
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Value", value: "value" },
        {
          text: "Action",
          // Row to replace the original template
          value: "actions",
        },
      ],

            initialCoins: '',
            coinPerSms: '',
            settings: [],
            loading: false,
            currentSettings:{}
        }
    },

    methods:{
        openEditDialog(item){
            this.editDialog = true;
            Object.assign(this.currentSettings, item)
        },
        getSettings(){
            this.$General.getSettings().then(data=>{
                this.settings = data.data.data;
                }).catch(error=>{
                console.log(error);
            })
        },
        editSetting(){
            this.loading = true;
            this.currentSettings.numberValue = parseInt(this.currentSettings.value)
            this.$General.updateSettings(this.currentSettings).then(data=>{
                this.loading = false;
                this.editDialog = false;
                this.getSettings();
            }).catch(error=>{
                this.loading = false;
                console.log(error);
            })
        },
        underScoreFormatter(val){
            
            return val? val.split('_').join(' ').toLowerCase(): '';
        }
    },

    mounted(){
        this.getSettings();
    }
}
</script>